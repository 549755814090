<nav class="navbar navbar-expand-sm nav-master">
  <button class="icon hamburger navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-content" aria-controls="nav-content" aria-expanded="false" aria-label="Toggle navigation"></button>
  <div class="collapse navbar-collapse navbar-collapse-master w-100" id="nav-content">
    <ul class="navbar-nav d-flex justify-content-center w-100">
      <li class="nav-item">
        <a class="scrollLink" routerLink="/" fragment="roadmap">ROADMAP</a>
      </li>
      <li class="nav-item">
        <a class="scrollLink" routerLink="/" fragment="about">ABOUT</a>
      </li>
      <li class="nav-item">
        <a class="scrollLink" routerLink="/" fragment="faq">FAQ</a>
      </li>
      <li class="nav-item">
        <a class="scrollLink" href="https://forums.zaviinetstudios.com/categories/voids-adrift.3/">FORUMS</a>
      </li>
      <!--<li class="nav-item">
        <a class="scrollLink" routerLink="/create-checkout" *ngIf="!payment.ownsGame()">DONATE & SUPPORT US</a>
        <a class="scrollLink" routerLink="/purchases" *ngIf="payment.ownsGame()">Purchases</a>
      </li>-->
      <!--<li class="nav-item" *ngIf="this.auth.isLoggedIn()">
        <a class="scrollLink" routerLink="/purchases">My Account</a>
      </li>
      <li class="nav-item">
        <a class="scrollLink"(click)="this.auth.logout()" href="" *ngIf="this.auth.isLoggedIn()">Logout</a>
        <a class="scrollLink" routerLink="login" *ngIf="!this.auth.isLoggedIn()">login</a>
      </li>-->
    </ul>
  </div>
</nav>
