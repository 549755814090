import { Injectable } from '@angular/core';
import {AuthenticationService} from "./authentication.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apiUrl = environment.config.apiUrl;

  ownsGameValue: boolean = false;
  ownsGameSet: boolean = false;

  ownsGame() : boolean {
    if(!this.auth.isLoggedIn()) {
      this.ownsGameValue = false;
      this.ownsGameSet = false;
      return false;
    }
    if(this.ownsGameSet)
      return this.ownsGameValue;

    const form = this.GetAuthedFormData();

    this.http.post(this.apiUrl + "/api/owns-game", form, {responseType: 'text'}).subscribe(data => {
      this.ownsGameValue = (data.includes("true"));
      this.ownsGameSet = true;
      return this.ownsGameValue;
    }, error => {
      console.error(error);
    });
    return false;
  }
  constructor(private router: Router, private http: HttpClient,private auth: AuthenticationService) { }

  attemptPurchase()
  {
    throw new Error();
    const redirect = "/create-checkout";
    if(!this.auth.isLoggedIn()) {
      this.router.navigate(['/login'], {queryParams: {redirect: redirect}});
    }else {
      this.ownsGameSet = false;
      const form = this.GetAuthedFormData();

      this.http.post(this.apiUrl + "/api/create-checkout-session", form, {responseType:'text'}).subscribe(data => {
        if(data.includes("http"))
          window.location.href = data;
        else
          this.router.navigate([data]);
      }, error => {
        console.error(error);
      });
    }
  }

  public GetAuthedFormData(): FormData
  {
    const form = new FormData();

    let sessionTicket = this.auth.loginInstance?.sessionTicket;
    if(!sessionTicket) {
      sessionTicket = "";
    }
    form.append('sessionTicket', sessionTicket);

    let playFabId = this.auth.loginInstance?.playFabId;
    if(!playFabId) {
      playFabId = "";
    }
    form.append('playFabId', playFabId);

    return form;
  }
}
