import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ClientComponent} from "./modules/client/client.component";

const routes: Routes = [
  {
    path: '',
    component: ClientComponent,
    loadChildren: () => {
      return import('./modules/client/client.module').then(
        m => m.ClientModule
      );
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
