<div id="footer-container">
  <div id="footer-content">

    <img class="mgn-btm-20" src="/assets/img/small_logo.png" width="" height="" alt="">
    <h5 class="bold gold mgn-btm-10">Sign up for our Newsletter</h5>
    <div class="flx-dp-f flx-ai-c mgn-btm-40">
      <!-- Begin Mailchimp Signup Form -->
      <div id="mc_embed_signup">
        <form action="https://zaviinetstudios.us1.list-manage.com/subscribe/post?u=a36e172c0d19db6c05a4ab00a&amp;id=97bf5c63f6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <div id="mc_embed_signup_scroll">

            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
            <div class="mc-field-group">
              <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
              </label>
              <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Enter your email">>
            </div>
            <div id="mce-responses" class="clear">
              <div class="response" id="mce-error-response" style="display:none"></div>
              <div class="response" id="mce-success-response" style="display:none"></div>
            </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_a36e172c0d19db6c05a4ab00a_97bf5c63f6" tabindex="-1" value=""></div>
            <div class="clear"><button type="submit" name="subscribe" id="mc-embedded-subscribe">Subscribe</button></div>
          </div>
        </form>
      </div>
      <!--End mc_embed_signup-->
    </div>
    <p class="white mgn-btm-20 spaced-text">&copy; & &trade; 2022 <a href="https://zaviinetstudios.com/">Zaviinet Studios</a>. All Rights Reserved.<br>Voids Adrift&reg; is a registered trademark of <a href="https://zaviinetstudios.com/">Zaviinet Studios</a>.</p>
    <div class="mgn-btm-20" id="footer-links">
      <a href="https://store.steampowered.com/app/1123160/Voids_Adrift/" target="_blank">
        <svg>
          <use xlink:href="/assets/img/icons.svg#steam"></use>
        </svg>
      </a>
      <a href="https://discord.gg/x78SHPGued">
        <svg>
          <use xlink:href="/assets/img/icons.svg#discord" target="_blank"></use>
        </svg>
      </a>
      <a href="https://www.reddit.com/r/VoidsAdrift/">
        <svg>
          <use xlink:href="/assets/img/icons.svg#reddit" target="_blank"></use>
        </svg>
      </a>
      <a href="https://twitter.com/VoidsAdrift">
        <svg>
          <use xlink:href="/assets/img/icons.svg#twitter" target="_blank"></use>
        </svg>
      </a>
    </div>
    <a class="scrollLink" onclick="document.getElementById('splash-container').scrollIntoView({behavior: 'smooth'})">Return to Top</a>
  </div>
</div>
