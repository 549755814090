import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {PartialObserver} from "rxjs";

interface LoginResult {
  playFabId: string;
  sessionTicket: string;
}
@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  loginInstance: LoginResult | null;
  apiUrl = environment.config.apiUrl;

  constructor(private http: HttpClient, private router: Router) {
    this.loginInstance = null;
  }

  public isLoggedIn() : boolean
  {
    return (this.loginInstance != null)
  }

  logout() {
    this.loginInstance = null;
  }

  attemptLogin(user:string, pass:string, redirect:string)
  {
    if (this.isLoggedIn()) {
      if(redirect=== null || redirect == "")
        this.router.navigate(['/']);
      else
        this.router.navigateByUrl(redirect);
    } else {
      const form = new FormData();
      form.append('username', user);
      form.append('password', pass);
      this.http.post<LoginResult>(this.apiUrl + '/api/login', form).subscribe(this.afterLogin(redirect, '/login'))
    }
  }

  attemptRegister(user:string, email:string, pass:string, redirect:string)
  {
    const form = new FormData();
    form.append('username', user);
    form.append('email', email);
    form.append('password', pass);
    this.http.post<LoginResult>(this.apiUrl+'/api/register', form).subscribe(this.afterLogin(redirect, '/register'))
  }

  private afterLogin(redirect: string, errorRoute: string): PartialObserver<LoginResult> {
    return {
      next: data => {
        this.loginInstance = data;
        if (redirect === null || redirect == "")
          this.router.navigate(['/']);
        else
          this.router.navigateByUrl(redirect);
      },
      error: error => {
        //this.errorMessage = error.message;
        console.error('There was an error!', error);
        if (redirect)
          this.router.navigate([errorRoute], {queryParams: {error: 'true', redirect: redirect}});
        else
          this.router.navigate([errorRoute], {queryParams: {error: 'true'}});
      }
    };
  }
}
